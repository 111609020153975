<template>
  <b-card class="page-content">
    <b-row>
      <b-col md="10">
        <page-breadcrumb title="Action records" class="mb-3" />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between">
      <h2 class="mb-2">Action records</h2>
    </div>
    <div class="d-flex align-items-end">
      <date-picker v-model="timeRange" is-range style="z-index: 1000; margin-bottom: -3px;" mode="date">
        <template v-slot="{ inputValue, inputEvents }">
          <label>Filter Date</label>
          <div class="d-flex justify-content-center align-items-center w-auto">
            <b-form-input
              :value="inputValue.start ? $options.filters.local_date(inputValue.start) : ''"
              v-on="inputEvents.start"
            />
            <svg style="width: 3em; height: 3em;"
              class="mx-1"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
            <b-form-input
              :value="inputValue.end ? $options.filters.local_date(inputValue.end) : ''"
              v-on="inputEvents.end"
            />
          </div>
        </template>
      </date-picker>
      <b-form-input class="mx-3 w-25" label="Search by title"
        v-model="title"
        id="title"
        type="text"
        placeholder="Search by title"
      />
      <loading-button
        variant="primary"
        icon="RefreshCcwIcon"
        :is-loading="isLoading"
        @click="getResults"
        size="sm"
      >
        Reload
      </loading-button>
    </div>
    
    <section class="py-3">
      <action-table :display-results="displayResults" :is-loading="isLoading" />
    </section>

    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="currentPage"
      :total-rows="totalItems"
      :per-page="itemsPerPage"
    />
  </b-card>
</template>

<script>
import service from "../service"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import ActionTable from './_components/ActionTable.vue'

export default {
  components: {
    DatePicker,
    ActionTable
  },

  data() {
    return {
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 30,
      displayResults: [],
      timeRange: {
        start: null,
        end: null,
      },
      title: null,
      isLoading: false
    };
  },

  watch: {
    currentPage() {
      this.getResults();
    },
    'timeRange.end'() {
      this.getResults()
    },
    'title'() {
      this.getResults()
    },
  },

  created() {
    this.getResults();
  },

  methods: {
    async getResults() {
      this.isLoading = true
      let response = await service.getList({
        time_from: this.timeRange.start,
        time_to: this.timeRange.end,
        page: this.currentPage,
        limit: this.itemsPerPage,
        search_key: this.title,
      });
      let results = [];
      if (response.data.type === "DATA") {
        results = response.data.data.list
        this.displayResults = results
        this.totalItems = response.data.data.total
      }
      this.isLoading = false
    }
  },
};
</script>
